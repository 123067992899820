// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import { Avatar, CardHeader } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { New } from './components/News';
import { Grid } from '@mui/material';
import Public from './components/Public';

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

const Main = () => {
  return (
    <main id="main">
      <div class="container">
        <div class="row section topspace">
          <h2 class="section-title"><span>παιδιατρικο ιατρειο</span></h2>
        </div>

        <div class="row section featured topspace">
          <h2 class="section-title"><span>ενημερωτικο υλικο</span></h2>
        </div>

        <Public isImage={false} desc="γενικα" col={[
          {
            desc: "εποχικη γριπη",
            url: "assets/pdf/gripi.pdf",
          },
          {
            desc: "πως να μαθετε να καταπινετε",
            url: "assets/pdf/swallow.png",
          },
          {
            desc: "5 κλειδια για πιο ασφαλεις παραδοσιακες αγορες τροφιμων",
            url: "assets/pdf/ΚΛΕΙΔΙΑ.jpg",
          },
          {
            desc: "οδηγιες για ασφαλεις διακοπες - προγραμματισμoς ταξιδιου",
            url: "assets/pdf/ΟΔΗΓΙΕΣ ΓΙΑ ΑΣΦΑΛΕΙΣ ΔΙΑΚΟΠΕΣ.pdf",
          },
          {
            desc: "ΥΓΙΕΙΝΗ ΔΙΑΤΡΟΦΗ",
            url: "assets/pdf/diet.PNG",
          }, {
            desc: "ΑΣΦΑΛΕΙΑ ΤΡΟΦΙΜΩΝ",
            url: "assets/pdf/5keys-greek.pdf",
          }, {
            desc: "ΕΠΙΛΟΓΗ ΑΝΤΙΗΛΙΑΚΟΥ",
            url: "assets/pdf/how-to-select-sunscreen-infographic.pdf",
          }, {
            desc: "ΚΑΘΑΡΙΣΜΌΣ ΧΕΡΙΩΝ",
            url: "assets/pdf/plysimo.jpeg"
          },
        ]} />

        <Public isImage={false} desc="βρεφικη ηλικια" col={[
          {
            desc: "οδηγιες για τις πρώτες ημέρες",
            url: "assets/pdf/firstdays.pdf",
          },
          {
            desc: "1η εβδομαδα",
            url: "assets/pdf/week1.pdf",
          },
          {
            desc: "2η εβδομαδα",
            url: "assets/pdf/week2.pdf",
          },
          {
            desc: "3η εβδομαδα",
            url: "assets/pdf/week3.pdf",
          },
          {
            desc: "4η εβδομαδα",
            url: "assets/pdf/week4.pdf",
          }, {
            desc: "Ασφαλης βρεφικος υπνος",
            url: "assets/pdf/Ασφαλής βρεφικός ύπνος.pdf",
          }, {
            desc: "διατροφικες συστασεις και μεριδες για παιδια",
            url: "assets/pdf/Διατροφικές συστάσεις και μερίδες για παιδιά.PNG",
          }, {
            desc: "εποχικοτητα τροφων",
            url: "assets/pdf/Εποχικότητα τροφών.PNG"
          }, {
            desc: "οδηγιες παρασκευης βρεφικου γαλατος",
            url: "assets/pdf/Οδηγίες παρασκευής βρεφικού γάλατος.pdf",
          }, {
            desc: "οδηγος για τη σιτηση βρεφους",
            url: "assets/pdf/ΟΔΗΓΟΣ ΓΙΑ ΤΗ ΣΙΤΗΣΗ ΒΡΕΦΟΥΣ.pdf",
          }
        ]} />

        <Public isImage={false} desc="παιδικη ηλικια" col={[
          {
            desc: "οδηγος για την πρωτη σχολικη χρονια",
            url: "assets/pdf/schoolyear.pdf",
          }, {
            desc: "παιδικη παχυσαρκια",
            url: "assets/pdf/Παιδική Παχυσαρκία.pdf",
          }, {
            desc: "εμβολιασμος εναντι του ιου των ανθρωπινων κονδυλωματων",
            url: "assets/pdf/Εμβολιασμός έναντι του ιού των ανθρωπίνων κονδυλωμάτων.pdf"
          },
        ]} />

        <Public isImage={false} desc="εφηβικη ηλικια" col={[
          {
            desc: "οδηγος επιτυχιας για τις σχολικες εξετασεις",
            url: "assets/pdf/success.pages",
          }
        ]} />

        <div id="#videos" class="row section topspace">
          <h2 class="section-title"><span>βιντεο</span></h2>

          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <YoutubeEmbed embedId="N4fcYPQQzwk" />
            </Grid>
            <Grid item xs={12} md={6}>
              <YoutubeEmbed embedId="AmeGYKV-L5s" />
            </Grid>
            <Grid item xs={12} md={6}>
              <YoutubeEmbed embedId="z5kVsWptIwQ" />
            </Grid>
          </Grid>
        </div>
      </div>
    </main>
  );
}

export default Main;
