import * as React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import ModalImage from "react-modal-image";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Card, Button, CardActions } from '@mui/material';

const parse = v => {
  const tmp = parseFloat(v);

  if (isNaN(tmp)) {
    return 0
  } else {
    return tmp
  }
}

const Main = () => {
  const [father, setFather] = React.useState("");
  const [mother, setMother] = React.useState("");
  const [sex, setSex] = React.useState("");
  const [result, setResult] = React.useState(null);

  return (
    <main id="main">
      <div class="container">
        <div class="row section topspace">
          <h2 class="section-title"><span>υπολογισμος τελικου αναστηματος</span></h2>
        </div>

        <div class="row section featured topspace">
          <article class="post">

            <div class="entry-content lead">
              <p>Δεν υπάρχει αποδεδειγμένος τρόπος να προβλέψουμε το τελικό ύψος ενός παιδιού. Εντούτοις, έχουμε διαθέσιμους μαθηματικούς τύπους που μπορεί να μας δώσουν μια ασφαλή εκτίμηση με μικρό περιθώριο σφάλματος.</p>
              <p>Σύμφωνα με έναν από αυτούς, αρκεί να διπλασιάσουμε το ύψος ενός αγοριού στην ηλικία των 2 ετών ή ενός κοριτσιού στην ηλικία των 18 μηνών.</p>
              <p>Σε έναν άλλο τύπο εκτιμάται ο μέσος όρος του ύψους των γονιών προσθέτοντας ή αφαιρώντας 8 εκατοστά ανάλογα  με το φύλο του παιδιού.</p>
              <p>
              Ο πλέον δοκιμασμένος και έγκυρος τύπος είναι για τα αγόρια [(ύψος μητέρας + 13cm) + ύψος πατέρα]/2 ενώ για τα κορίτσια είναι [(ύψος πατέρα - 13cm) + ύψος μητέρας]/2.
              </p>
              <p>
              Στο τελικό αποτέλεσμα δίνεται πάντα το ενδεχόμενο διαφοροποίησης κατά δύο σταθερές απόκλισης (+/-2SD).
              </p>
            </div>

            <Grid container spacing={1} direction="row" alignItems="center" justifyContent="center" sx={{ margin: "auto", maxWidth: 500 }}>
              <Grid item xs={12} mb={2}>
                <ModalImage small="assets/images/lowres/height.jpg" large="assets/images/lowres/height.jpg" alt="" />
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1} component={Card} p={1} pt={1}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Φύλο</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Age"
                      onChange={(event) => {
                        setSex(event.target.value);
                      }}
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="male">Αγόρι</MenuItem>
                      <MenuItem value="female">Κορίτσι</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    label="Υψος πατέρα (cm)"
                    value={father}
                    onChange={(event) => {
                      setFather(parse(event.target.value));
                    }}
                  />
                  <TextField
                    label="Ύψος μητέρας (cm)"
                    value={mother}
                    onChange={(event) => {
                      setMother(parse(event.target.value));
                    }}
                  />
                  {result !== null && <TextField
                    label="Ύψος παιδιού"
                    disabled={true}
                    value={result}
                  />}
                  <CardActions>
                    <Button
                      variant="contained"
                      onClick={(event) => {
                        if(isNaN(father) || isNaN(mother)) {
                          setResult("Λάθος τιμές");
                        } else {
                          setResult(sex === "male" ? (father+mother+13)/2 : (father+mother-13)/2);
                        }
                      }}
                    >
                      υπολογισμος
                    </Button>
                  </CardActions>
                </Stack>
              </Grid>
            </Grid>
          </article>
        </div>
      </div>
    </main>
  );
}

export default Main;
