import Grid from '@mui/material/Grid';

export const New = ({ desc, url }) => {
  return (
    <a style={{ width: "100%" }} href={url}>
      <pre class="btn-lg" style={{
        // border: 0,
        textAlign: "center",
        fontSize: "1.1rem",
        padding: 40,
        width: "100%",
        textTransform: "uppercase",
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        // textShadow: "0 1px 0px #780d33",
        wordBreak: "normal",
        borderRadius: 0,
        verticalAlign: "middle",
        border: "4px solid #30bec7ff",
        backgroundColor: "white",
      }}>
        {desc.normalize("NFD").replace(/\p{Diacritic}/gu, "")}
      </pre>
    </a>
  )
}

export const New2 = ({ desc, url }) => {
  const normalizedDesc = desc
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "");

  return (
    <a
      href={url}
      style={{
        display: "block",
        textDecoration: "none",
        width: "100%",
      }}
    >
      <pre
        className="btn-lg"
        style={{
          fontSize: "1.2rem",
          padding: "20px 40px",
          width: "100%",
          textTransform: "uppercase",
          fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
          wordBreak: "break-word",
          borderRadius: "8px",
          textAlign: "center",
          verticalAlign: "middle",
          border: "3px solid #30bec7",
          backgroundColor: "#f9f9f9",
          color: "#333",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "all 0.3s ease-in-out",
          cursor: "pointer",
        }}
        onMouseEnter={(e) =>
          (e.target.style.backgroundColor = "#30bec7") ||
          (e.target.style.color = "white")
        }
        onMouseLeave={(e) =>
          (e.target.style.backgroundColor = "#f9f9f9") ||
          (e.target.style.color = "#333")
        }
      >
        {normalizedDesc}
      </pre>
    </a>
  );
};

export const New3 = ({ desc, url }) => {
  const normalizedDesc = desc
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "");

  return (
    <a
      href={url}
      style={{
        display: "block",
        textDecoration: "none",
        width: "100%",
      }}
    >
      <pre
        className="btn-lg"
        style={{
          fontSize: "1.2rem",
          padding: "20px 40px",
          width: "100%",
          textTransform: "uppercase",
          fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
          wordBreak: "break-word",
          borderRadius: "8px",
          textAlign: "center",
          verticalAlign: "middle",
          border: "3px solid #30bec7",
          backgroundColor: "#ffffff",
          color: "#333",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
          transition: "all 0.3s ease-in-out",
          backgroundImage:
            "radial-gradient(circle, rgba(0, 0, 0, 0.02) 1%, rgba(0, 0, 0, 0) 100%)",
          backgroundSize: "10px 10px",
        }}
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = "#30bec7";
          e.target.style.color = "white";
          e.target.style.backgroundImage = "none";
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = "#ffffff";
          e.target.style.color = "#333";
          e.target.style.backgroundImage =
            "radial-gradient(circle, rgba(0, 0, 0, 0.02) 1%, rgba(0, 0, 0, 0) 100%)";
        }}
      >
        {normalizedDesc}
      </pre>
    </a>
  );
};

export const New4 = ({ desc, url }) => {
  const normalizedDesc = desc
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "");

  return (
    <a
      href={url}
      style={{
        display: "block",
        textDecoration: "none",
        width: "100%",
      }}
    >
      <pre
        className="btn-lg"
        style={{
          fontSize: "1.2rem",
          padding: "20px 40px",
          width: "100%",
          textTransform: "uppercase",
          fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
          wordBreak: "break-word",
          borderRadius: "8px",
          textAlign: "center",
          verticalAlign: "middle",
          border: "3px solid #2A8307", // Updated border color to match the website's color scheme
          backgroundColor: "#ffffff",
          color: "#333",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
          transition: "all 0.3s ease-in-out",
          backgroundImage:
            "radial-gradient(circle, rgba(0, 0, 0, 0.02) 1%, rgba(0, 0, 0, 0) 100%)",
          backgroundSize: "10px 10px",
        }}
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = "#2A8307"; // Updated hover background color
          e.target.style.color = "white";
          e.target.style.backgroundImage = "none";
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = "#ffffff";
          e.target.style.color = "#333";
          e.target.style.backgroundImage =
            "radial-gradient(circle, rgba(0, 0, 0, 0.02) 1%, rgba(0, 0, 0, 0) 100%)";
        }}
      >
        {normalizedDesc}
      </pre>
    </a>
  );
};

const Main = () => {
  return (
    <div class="row section featured topspace">
    <h2 class="section-title"><span>τελευταια νεα</span></h2>
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <New3 desc="λογοι απαλλαγης απο τον εμβολιασμο κατα του COVID-19" url="assets/pdf/Λόγοι-απαλλαγής-από-τον-εμβολιασμό-κατά-του-κορωνοϊού-COVID.pdf" />
      </Grid>
      <Grid item xs={12} md={6}>
        <New3 desc="επιδημιολογικα δεδομενα ιογενων λοιμωξεων αναπνευστικου" url="assets/pdf/Εβδομαδιαία Έκθεση Επιδημιολογικής Επιτήρησης Αναπνευστικών Λοιμώξεων Εβδομάδα 52 2024.pdf" />
      </Grid>
      <Grid item xs={12} md={6}>
        <New3 desc="Εθνικό Πρόγραμμα Εμβολιασμών παιδιών και εφήβων 2024" url="assets/pdf/2024-1.pdf" />
      </Grid>
      <Grid item xs={12} md={6}>
        <New3 desc="Οδηγίες για την Εποχική Γρίπη 2024-2025 – Αντιγριπικός Εμβολιασμός" url="assets/pdf/ΨΩΣΑ465ΦΥΟ-ΟΕΨ.pdf" />
      </Grid>
    </Grid>
  </div>
  );
}

export default Main;
