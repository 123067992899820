// import Card from './components/Card';
// import Grid from '@mui/material/Grid';
import Public from './components/Public';

const Main = () => {
  return (
    <main id="main">
      <div class="container">
        <Public isImage={false} desc="ενημερωτικο υλικο" col={[
          {
            desc: "Εθνικος Διατροφικος Οδηγος Για Βρεφη, Παιδια & Εφηβους",
            url: "http://www.diatrofikoiodigoi.gr/files/html/kids/files/assets/basic-html/index.html#1",
          },
          {
            desc: "Εθνικος Διατροφικος Οδηγος Για Γυναικες, Εγκυους & Θηλαζουσες",
            url: "http://www.diatrofikoiodigoi.gr/files/html/women/files/assets/basic-html/index.html#1",
          },
          {
            desc: "Διατροφη της Θηλαζουσας: Συστασεις, πρακτικες Οδηγιες και ειδικες Διαιτες",
            url: "https://www.moh.gov.gr/articles/health/dieythynsh-dhmosias-ygieinhs/metadotika-kai-mh-metadotika-noshmata/c388-egkyklioi/11584-enhmerwtiko-yliko-gia-th-diatrofh-ths-thhlazoysas",
          },
          {
            desc: "Συστασεις για την εισαγωγη στερεων τροφων στον πρωτο χρονο της ζωης",
            url: "https://www.moh.gov.gr/articles/health/dieythynsh-dhmosias-ygieinhs/metadotika-kai-mh-metadotika-noshmata/c388-egkyklioi/5750-systaseis-gia-thn-eisagwgh-sterewn-trofwn-ston-1o-xrono-ths-zwhs",
          },
        ]} />

        <Public
          isImage={false}
          desc="ενδεικτικο προγραμμα διατροφης"
          text="Διαμορφώνεται ανάλογα με τις διάφορες ατομικές και πολιτισμικές προτιμήσεις."
          col={[
          {
            desc: "εβδομαδιαιο προγραμμα διατροφης για παιδια",
            url: "assets/pdf/Εβδομαδιαίο πρόγραμμα.pdf"
          },
          {
            desc: "Μηνιαιο προγραμμα μεσημεριανων γευματων",
            url: "assets/pdf/Μηνιαίο πρόγραμμα μεσημεριανών γευμάτων.pdf"
          },
        ]} />
      </div>
    </main>
  );
}

export default Main;
